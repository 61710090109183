
import { Vue, Component } from 'vue-property-decorator'

import TerminalVue, { XtermVueInterface } from '@/components/xterm-vue/XtermVue.vue'
import terminalConfig from '../../logger-center/log-console/terminal.config'
import ApplicationApi, { Application, ApplicationType } from '@/api/maintain/application'
import AppInstanceApi, { AppInstance } from '@/api/maintain/app-instance'
import appInstanceManger from '@/api/maintain/app-instance-manger'
import deployRequestService from '@/libs/deploy-request-service'
import { DeployRequest } from '@/api/maintain/deploy-request'
import { ApplicationWebSocket } from '@/libs/application-websocket'
import { appInstanceStatus } from '@/views/test/moniting-appintace-helper'

@Component({
  name: 'AppInstanceDeployPage',
  components: { TerminalVue }
})
export default class AppInstanceDeployPage extends Vue {
  instanceId = 0

  instanceItem: AppInstance = {} as AppInstance

  application: Application = {} as Application

  /// wss连接
  ws?: ApplicationWebSocket

  /// 任务处理状态
   taskProcStatus: {
     loading: boolean;
     term?: XtermVueInterface;
   }={
     loading: false
   }

   /// 终端配置
  terminalOption = terminalConfig.terminalOption;

  created () {
    // 获取跳转传递过来的实例id
    const id = this.$route.params.id
    if (!id) {
      this.$Message.error('缺少必要参数id')
      return
    }
    this.instanceId = parseInt(id)
    this.loadDataFromServer()
  }

  // 加载下拉框选项
  loadDataFromServer () {
    AppInstanceApi.getItemById(this.instanceId)
      .then((response) => {
        const data = response.data
        this.instanceItem = data || {} as AppInstance
        this.$set(this.instanceItem, 'status', appInstanceStatus.PendConntion)
        this.getApplicationInfo(data?.applicationId!)
        this.openPcWss(this.instanceItem)
      })
      .catch((err) => {
        this.$Notice.error({
          title: '错误',
          desc: '获取实例信息失败:' + err.message
        })
      })
  }

  /**
 * 获取应用信息
 */
  getApplicationInfo (applicationId: number) {
    ApplicationApi.getItemById(applicationId)
      .then((response) => {
        const data = response.data
        this.application = data || {} as Application
      })
      .catch((err) => {
        this.$Notice.error({
          title: '错误',
          desc: '获取应用信息失败:' + err.message
        })
      })
  }

  /** 开始部署数据 */
  startDeploy () {
    if ([ApplicationType.WebFront, ApplicationType.NotStandardWebFront].includes(this.application.appType!)) {
      this.deploy()
      return
    }
    appInstanceManger.checkContainerData(this.instanceId).then((res) => {
      if (res.data.data) {
        this.$Modal.confirm({
          title: '确认',
          content: `<p>是否删除${this.instanceItem?.name}实例的历史数据?</p>`,
          cancelText: '不删除',
          onOk: async () => {
            appInstanceManger.deleteContainerData(this.instanceId).then(() => {
              this.deploy()
            })
          },
          onCancel: () => {
            this.deploy()
          }
        })
      } else {
        this.deploy()
      }
    })
  }

  // 部署
  async deploy () {
    this.taskProcStatus.loading = true
    /// 获取部署请求数据
    const requestDeployData = deployRequestService.createDeployData(
      [this.instanceItem],
      this.instanceItem,
      this.instanceItem.applicationType
    )
    if (requestDeployData) {
      for (let i = 0; i < requestDeployData.length; i++) {
        await this.processDeploy(requestDeployData[i])
      }
      this.taskProcStatus.loading = false
    }
  }

  /**
   * 处理部署任务
   * @param reqest
   */
  processDeploy (reqest: DeployRequest[]) {
    const that = this
    return new Promise((resolve, reject) => {
      deployRequestService.requestDeploy(reqest, {
        onMessage (taskMessage) {
          that.taskProcStatus.term?.addMessage(taskMessage.message)
        },
        onError (err) {
          that.taskProcStatus.term?.addLineMessage('任务发生错误:' + err.message)
        },
        onOpen () {
          that.taskProcStatus.term?.addLineMessage('在podman启动部署任务')
        },
        onFinish () {
          that.taskProcStatus.term?.addLineMessage('任务结束')
        }
      }).then((res) => {
        resolve(res)
      }).catch((err) => {
        this.$Message.error(err.message)
        this.taskProcStatus.loading = false
        reject(err)
      })
    })
  }

  /**
   * 是否为前端项目
   */
  get isWebFrontApp () {
    if (this.instanceItem) {
      if ([ApplicationType.WebFront, ApplicationType.NotStandardWebFront].includes(this.instanceItem.applicationType)) {
        return true
      }
    }
    return false
  }

  /// 打开一个新的wss监控
  openPcWss (item: AppInstance) {
    if (this.isWebFrontApp) {
      return
    }
    if (!item.pcServerId) {
      return
    }
    const that = this
    appInstanceManger.getInstanceStatusMonitorWebSocket(item.pcServerId, location.protocol === 'https:' ? item.httpsManagerPort : item.mangerPort, {
      fixed: 10000,
      callback: (data) => {
        that.$nextTick(() => {
          if (item.containerName && data[item.containerName] !== undefined) {
            this.$set(this.instanceItem, 'status', data[item.containerName] ? appInstanceStatus.Runing
              : appInstanceStatus.Stop)
          } else {
            this.$set(this.instanceItem, 'status', appInstanceStatus.NodeDeploy)
          }
        })
      },
      onError () {
        that.$nextTick(() => {
          that.instanceItem.status = appInstanceStatus.ConntionError
        })
      }
    }).then((ws) => {
      this.ws = ws
      /** 若10s后状态未变更则置为联结失败 */
      setTimeout(() => {
        if (this.instanceItem.status === appInstanceStatus.PendConntion) {
          this.instanceItem.status = appInstanceStatus.ConntionError
        }
      }, 10000)
    })
  }

  /**
   * 控制台准备完成
   */
  onTerminalReady () {
    this.taskProcStatus.term = (this.$refs.term as any) as XtermVueInterface
  }

  finishDeploy () {
    this.$Message.success('您已部署实例成功，可返回应用中心查看')
  }

  /** 关闭连接 */
  closePcWss () {
    this.ws?.close()
  }

  /**
   * 释放资源
   */
  destroyed () {
    this.closePcWss()
  }

  /**
   * 实例状态文字转换
   */
  getInstanceStatusText () {
    switch (this.instanceItem.status) {
      case 0: return '待联结'
      case 2: return '已停止'
      case 3: return '运行中'
      case 4: return '未部署'
      case 5: return '联结错误'
      default: return '未知'
    }
  }

  /**
   * 实例状态样式转换
   */
  getInstanceStatusStyle () {
    switch (this.instanceItem.status) {
      case 1: return 'color:#66ff00;font-weight:700;font-size:14px'
      case 2: return 'color:#ffcc00;font-weight:700;font-size:14px'
      case 3: return 'color:#000000;font-weight:700;font-size:14px'
      case 4: return 'color:#000000;font-weight:700;font-size:14px'
      default: return 'color:#000000;font-weight:700:font-size:14px'
    }
  }
}
