/**
 * 镜像资源
 */

import DefaultApiService, { BaseHasTimeEntity } from '@/libs/api-request'
import { DataApiResult } from '@/libs/http-request'

/**
 * 镜像资源接口
 */
export interface ImagesResource extends BaseHasTimeEntity {
  /** 名称 */
  name?: string;
  /** 标题 */
  title?: string;
  /** 介绍 */
  introduce?: string;
  /** 镜像名 */
  imageName?: string;
  /** 镜像类型 */
  imageType?: ImageType;
  /** 镜像类型名称 */
  imageTypeName?: string;
  /** 是否行必果提供 */
  fromXbg?: boolean;
  /** 在docker hub的介绍地址 */
  descriptUrl?: string;
  /** 默认值配置文件名，如果为空，则存贮在数据库中 */
  defaultConfigFile?: string;
  /** 归属团队id,如果为空为系统 */
  teamId?: number;
}

/** 镜像类型枚举 */
export enum ImageType {
  /** 注册中心 */
  REGISTER_CENTER = 1,
  /** api网关 */
  GATEWAY,
  /** 配置中心 */
  CONFIG_CENTER,
  /** 安全中心 */
  SECURITY_CENTER,
  /** 关系数据库 */
  RELATIONAL_DB,
  /** 缓存数据库 */
  CACHE_DB,
  /** nosql数据库 */
  NOSQL_DB,
  /** 消息服务 */
  MESSAGES_SERVICE,
  /** nginx */
  NGINX,
  /** 其他 */
  ELSE = 99
}

/**
 * 团队服务请求api接口
 */
class ImagesResourceApi extends DefaultApiService<ImagesResource> {
  /**
   * 通过名称查询镜像
   * @param imageName
   * @param maxItem
   */
  public queryByName (imageName: string, maxItem?: number) {
    return this.requestList('queryByName', {
      name: imageName,
      max: maxItem
    })
  }

  /**
    * 取得id对应的对象的值
    * @param id 要删除记录的主键id
    */
  public getItemById (id: number): Promise<DataApiResult<ImagesResource>> {
    return this.request(`${id}`, undefined, 'GET')
  }

  constructor () {
    super('/api/maintain/images-resource')
  }
}

export default new ImagesResourceApi()
